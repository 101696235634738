import React from 'react'

import Head from 'next/head'

import Layout from '../app/components/layout/layout'
import Home from '../app/containers/home/home'

import StrapiAPI from '../app/data/strapi';

/**
 * equivalent to landing page
 */
const HomePage = ({categories}) => {

    return (
        <React.Fragment>
            <Head>
                <title>BGV | Home</title>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, user-scalable=no'/>
            </Head>
            <Layout>
                <Home categories={categories} />
            </Layout>
        </React.Fragment>
    );
}

export async function getStaticProps() {
    const response = await StrapiAPI.getAllCategories()
    const categories = response.data || null

    return {
        props: {categories},
        revalidate: 60, // In seconds
    }
}

export default HomePage
